import React from 'react'
import EnLayout from '../components/en_layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import ImagenPeliculas from '../components/imageComponents/imagenPeliculas'

const IndexPage = () => (
  <EnLayout>
    <SEO title='foils' />
    <Header siteTitle='foils' />

    <Container>
      <div className='container'>
        <div className='row justify-content-md-center'>
          <div className='col-12 col-md-5'>
            <ImagenPeliculas />
          </div>
          <div className='col-12 col-md-7 conmargen'>
            <p>
              <b><span className="oro"> OR-PRINTER </span></b> supplies all kinds of foils:
            </p>
            <ul>
              <li> Mate </li>
              <li> Brightness </li>
              <li> Holographic </li>
              <li> Whole range of colors </li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  </EnLayout>
)

export default IndexPage
